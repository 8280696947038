import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { fontSizeMonthlyChart, getPadding, ResponsiveLableSize } from '../../../common/commonFun';

const CoPayMonthlyTreatmentChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const monthlyTreatmentC1 = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: [
            ['1 Dispensed  ', 'scripts'],
            ['2 Dispensed  ', 'scripts'],
            ['3 Dispensed ', 'scripts'],
            ['4 Dispensed ', 'scripts'],
            ['5 Dispensed ', 'scripts'],
            ['6 Dispensed ', 'scripts'],
            ['Enrolled ', '7+ months'],
          ],
          datasets: [{
             label: 'Clinicians',
             data: [100, 97, 95, 94, 92, 87, 84],
             backgroundColor: 'rgba(107, 186, 189, 1)',
             borderWidth: 1,
          }]
       },
       options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
           padding: getPadding()
        },
        scales: {
           x: {
              grid: {
                 display: false
              },
              ticks: {
                 font: {
                    family: 'Open Sans',
                    weight: 400,
                    size: ResponsiveLableSize()
                 },
                 autoSkip: true,
              },
              border: {
                 color: '#858586',
              },
           },
           y: {
              beginAtZero: true,
              min: 0,
              max: 100,
              ticks: {
                 stepSize: 20,
                 font: {
                    family: 'Open Sans',
                    weight: 500,
                    size: ResponsiveLableSize()
                 }
              },
              border: {
                 color: '#858586',
              },
              title: {
                 display: true,
                 text: 'Percentage',
                 color: '#6D6F71',
                 font: {
                    family: 'Open Sans',
                    weight: 400,
                    size: 16,
                 },
                 position: 'right',
                 rotation: 0,
                 padding: 20,
              },
           }
        },
        plugins: {
           legend: {
              display: false,
           },
           datalabels: {
            anchor: (context) => {
               const value = context.dataset.data[context.dataIndex];
               const maxValue = Math.max(...context.chart.data.datasets[0].data);
               const percentage = Math.round((value / maxValue) * 100);
               return percentage > 10 ? 'end' : 'end'; // Adjust based on percentage
             },
             align: (context) => {
               const value = context.dataset.data[context.dataIndex];
               const maxValue = Math.max(...context.chart.data.datasets[0].data);
               const percentage = Math.round((value / maxValue) * 100);
               return percentage > 10 ? 'start' : 'end'; // Adjust based on percentage
             },
             color: (context) => {
               const value = context.dataset.data[context.dataIndex];
               const maxValue = Math.max(...context.chart.data.datasets[0].data);
               const percentage = Math.round((value / maxValue) * 100);
               return percentage > 10 ? '#fff' : '#2d9b9f'; // Change color dynamically
             },
              font: {
                 family: 'Open Sans',
                 size: fontSizeMonthlyChart(),
                 weight: 700
              },
              offset: 10,
              formatter: (value, context) => {
               const maxValue = Math.max(...context.chart.data.datasets[0].data);
               const percentage = Math.round((value / maxValue) * 100);
               return `${percentage}%`; // Display percentage
             },
           }
        }
     },
      });

      return () => monthlyTreatmentC1.destroy();
    }
  }, []);

  return (

    <div className="row">
      <div className="col-sm-12">
        <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
          <div className="card-header bg-transparent border-0">
            <h3 className="text-secondary fw-bold fs-30 text-center">6 month treatment compliance</h3>
          </div>
          <div className="card-body">
            <div>
              <canvas ref={chartRef} id="monthlyTreatmentC1" width="100%" height="450"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default CoPayMonthlyTreatmentChart;
