import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { fontSizeMonthlyChart, getPadding, ResponsiveLableSize } from '../../../common/commonFun';

const MonthlyTreatmentChartTwo = () => {
  const data = {
    labels: [
      ['1 Dispensed', 'Scripts'],
      ['2 Dispensed', 'Scripts'],
      ['3 Dispensed', 'Scripts'],
      ['4 Dispensed', 'Scripts'],
      ['5 Dispensed', 'Scripts'],
      ['6 Dispensed', 'Scripts'],
      ['7 Dispensed', 'Scripts'],
      ['8 Dispensed', 'Scripts'],
      ['9 Dispensed', 'Scripts'],
      ['10 Dispensed', 'Scripts'],
      ['11 Dispensed', 'Scripts'],
      ['12 Dispensed', 'Scripts'],
      ['Enrolled', '13+ Months'],
    ],
    datasets: [{
      label: 'Monthly Sales',
      data: [100, 98, 97, 95, 95, 93, 92, 90, 90, 88, 85, 84, 84],
      backgroundColor: 'rgba(90, 123, 146, 1)',
      borderWidth: 1,
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: getPadding() },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: {
            family: 'Open Sans',
            weight: 400,
            size: ResponsiveLableSize()
          },
          autoSkip: true,
          padding: 2,
          minRotation: 50,
        },
        border: { color: '#858586' },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          font: {
            family: 'Open Sans',
            weight: 500,
            size: ResponsiveLableSize(),
          },
        },
        border: { color: '#858586' },
        title: {
          display: true,
          text: 'Percentage',
          color: '#6D6F71',
          font: { family: 'Open Sans', weight: 400, size: 16 },
          position: 'right',
          rotation: 0,
          padding: 20,
        },
      }
    },
    plugins: {
      legend: { display: false },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#114364',
        font: { family: 'Open Sans', size: fontSizeMonthlyChart(), weight: 700 },
        offset: 2,
        formatter: (value, context) => {
          let maxValue = Math.max(...context.chart.data.datasets[0].data);
          let percentage = Math.round((value / maxValue) * 100);
          return percentage + '%';
        }
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      options.plugins.datalabels.font.size = fontSizeMonthlyChart();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [options]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card rounded-0 border-dark-700 h-100 pt-3">
          <div className="card-body">
            <h3 className="text-turquoise fw-bold fs-30 text-center">12 Month Treatment Compliance</h3>
            <div>
              <Bar data={data} options={options} width="100%" height="450" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyTreatmentChartTwo;

